// src/App.js

import React from 'react';
import Search from './components/Search'; // Import your Search component
import Footer from './components/Footer'; // Import the Footer component
import './App.css'; // Your main CSS file

const App = () => {
    return (
        <div className="app">
            <Search /> {/* Your existing search component */}
            <Footer /> {/* Add the Footer here */}
        </div>
    );
};

export default App;
