// src/components/Footer.js

import React from 'react';
import './Footer.css'; // Import a CSS file for styling (optional)

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-links">
            <a href="https://scimatic.net" target="_blank" rel="noopener noreferrer">
                    SciMatic Hybrid Blockchain
                </a>
            </div>

            <div className="footer-links">
              
                <a href="https://scimatic.net/en/sale/0" target="_blank" rel="noopener noreferrer">
                    <img src="https://scimatic.org/storage/web/home.png" alt="SCI Coin" className="token-logo" /> {/* Placeholder for SCI Coin logo */}
                    Buy SCI Coin
                </a>
                <a href="https://scimatic.net/en/sale/51" target="_blank" rel="noopener noreferrer">
                    <img src="https://scimatic.net/assets/images/coin/SCI2p32x32.png" alt="SCI+ Token" className="token-logo" /> {/* Placeholder for SCI+ Token logo */}
                    Buy SCI+ Token
                </a>
                <a href="https://scimatic.net/en/sale/2" target="_blank" rel="noopener noreferrer">
                    <img src="https://scimatic.online/storage/icons/tokens/scimatic/susd.png" alt="SUSD Token" className="token-logo" /> {/* Placeholder for SUSD Token logo */}
                    Buy SUSD Token
                </a>
                <a href="https://scimatic.net/en/sale/3" target="_blank" rel="noopener noreferrer">
                    <img src="https://scimatic.net/assets/images/coin/TRYS.png" alt="TRYS Token" className="token-logo" /> {/* Placeholder for TRYS Token logo */}
                    Buy TRYS Token
                </a>
            </div>
            <p className="footer-text">&copy; {new Date().getFullYear()} SciMatic. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
