import React, { useState, useEffect } from 'react';
import { ethers, isAddress, formatEther, formatUnits } from 'ethers';
import './Search.css';

const Search = () => {
    const [input, setInput] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [latestBlockNumber, setLatestBlockNumber] = useState(null);
    
    const SUSD_TOKEN_ADDRESS = '0x65C4A0dA0416d1262DbC04BeE524c804205B92e8'; // SUSD token address
    const SCICHAIN_RPC = 'https://chain.scimatic.net'; // SUSD token address

    // Function to fetch the latest block number
    const fetchLatestBlockNumber = async () => {
        const provider = new ethers.JsonRpcProvider(SCICHAIN_RPC);
        const blockNumber = await provider.getBlockNumber();
        setLatestBlockNumber(blockNumber);
    };

    // Fetch the latest block number every 5 seconds
    useEffect(() => {
        fetchLatestBlockNumber(); // Fetch immediately on mount
        const interval = setInterval(fetchLatestBlockNumber, 5000); // Set interval to fetch every 5 seconds
        
        return () => clearInterval(interval); // Cleanup on unmount
    }, []); // Empty dependency array to run once on mount

    const handleSearch = async () => {
        setIsLoading(true); // Start loading

        try {
            setError(null);
            const provider = new ethers.JsonRpcProvider(SCICHAIN_RPC);

            if (isAddress(input)) {
                // Get SCI balance
                const sciBalanceRaw = await provider.getBalance(input);
                const sciBalance = formatEther(sciBalanceRaw);

                // Get SUSD balance
                const erc20Abi = [
                    'function balanceOf(address) view returns (uint256)',
                    'function decimals() view returns (uint8)',
                ];
                const susdTokenContract = new ethers.Contract(SUSD_TOKEN_ADDRESS, erc20Abi, provider);

                const [susdBalanceRaw, decimals] = await Promise.all([
                    susdTokenContract.balanceOf(input),
                    susdTokenContract.decimals(),
                ]);

                const susdBalance = formatUnits(susdBalanceRaw, decimals);

                // Set result with both balances
                setResult({
                    SCI: `SCI Balance: ${sciBalance} SCI`,
                    SUSD: `SUSD Balance: ${susdBalance} SUSD`,
                });
            } else if (input.length === 66 && input.startsWith('0x')) {
                // It's a transaction hash
                const tx = await provider.getTransaction(input);
                setResult(tx);
            } else {
                // Check if it's a token address
                const erc20Abi = [
                    'function name() view returns (string)',
                    'function symbol() view returns (string)',
                    'function totalSupply() view returns (uint256)',
                    'function decimals() view returns (uint8)', // Ensure decimals function is included
                ];
                const tokenContract = new ethers.Contract(input, erc20Abi, provider);

                try {
                    const name = await tokenContract.name();
                    const symbol = await tokenContract.symbol();
                    const totalSupply = await tokenContract.totalSupply();
                    const decimals = await tokenContract.decimals();
                    setResult({
                        name,
                        symbol,
                        totalSupply: formatUnits(totalSupply, decimals),
                    });
                } catch {
                    setError('Invalid token address or unable to fetch token data.');
                }
            }
        } catch (err) {
            setError('Error fetching data. Check your input or try again later.');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <div className="container">
            <h1 className="title">SciMatic Hybrid Blockchain</h1>
            <h2 className="subtitle">Explorer</h2>

            <p className="latest-block">Latest Block Number: {latestBlockNumber}</p> {/* Show latest block number */}
            
            <input
                className="input"
                type="text"
                placeholder="Enter hash, wallet address, or token address"
                value={input}
                onChange={(e) => setInput(e.target.value)}
            />

            <button className="button" onClick={handleSearch}>Search</button>

            {isLoading ? (
                <p className="loading">Loading...</p> // Display loading message or spinner
            ) : (
                <>
                    {error && <p className="error">{error}</p>}
                    {result && <pre className="result">{JSON.stringify(result, null, 2)}</pre>}
                </>
            )}
        </div>
    );
};

// Handle hover effect for button
document.addEventListener('mouseover', (e) => {
    if (e.target.matches('button')) {
        e.target.style.backgroundColor = '#2980b9';
    }
});
document.addEventListener('mouseout', (e) => {
    if (e.target.matches('button')) {
        e.target.style.backgroundColor = '#3498db';
    }
});

export default Search;
